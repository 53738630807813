import {TableBase} from "./table_base";

$(() => {
    if(app && app.DASHBOARD_CORE) {
        Object.keys(app.OPTIONS.gridstack_allowed).forEach((gridId) => {
            if(app.OPTIONS.gridstack_allowed[gridId].renderType !== 'table' ||
                !app.OPTIONS.gridstack_allowed[gridId].enabled ||
                app.OPTIONS.gridstack_allowed[gridId].table_custom !== false
            ) {
                return;
            }

            new TableBase(gridId);
        });
    } else {
        console.warn('DashboardCore not initalized.');
    }
});